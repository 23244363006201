@import "style/index.scss";

.img-wrap {
  position: relative;
  width: 100%;
  max-width: 75rem;
  padding: 0.75rem 0;
  display: flex;
  flex-wrap: wrap;
  @include start-center;
  column-gap: 0.875rem;
  row-gap: 0.875rem;
  .img-item {
    position: relative;
    &:hover {
      .img-btn-wrap {
        display: block;
      }
    }
  }

  img {
    width: 12.5rem;
    min-width: 7.5rem;
    height: 12.5rem;
    border-radius: 0.5rem;
  }

  img + div {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    span:hover {
      &::after {
        position: absolute;
        top: 0rem;
        left: 1rem;
        z-index: 2;
        background-color: #fff;
        content: attr(data-txt);
      }
    }
  }
  .img-btn-wrap {
    display: none;
    position: absolute;
    top: 1rem;
    right: -0.25rem;
    & > button,
    span,
    div {
      display: flex;
      margin-bottom: 0.25rem;
      padding: 0rem 0.5rem;

      &[data-n-use] {
        &::before {
          position: absolute;
          left: 1rem;
          content: "";
          height: 1.25rem;
          width: 0.5px;
          background-color: $black-900;
          transform: rotate(-30deg);
        }
      }
    }

    button {
      & > svg {
        background-color: rgba($color: #fff, $alpha: 0.7);
      }
    }
  }

  label[for="file-img"] {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 30px;
  }
}

.pay-inf {
  & > div {
    span {
      &:first-child {
        width: 12.5rem;
      }
    }
  }
}

button {
  &[data-lng] {
    min-width: 6rem;
    width: auto;
    height: 2.125rem;
    font-size: 0.875rem;
    text-align: center;
    background-color: #fff;
    border: 0.0625rem solid #dfe3e9;
    border-top-right-radius: 0.75rem;
    overflow: hidden;
    transition: all 0.15s linear;
  }
  &[data-lng="true"] {
    background-color: $gray-200;
  }
}

.ql-toolbar.ql-snow {
  display: flex;
  justify-content: flex-start;
  .ql-formats {
    display: inline-flex;
    width: auto;
    margin: 0;
  }
}
