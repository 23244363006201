@import "style/index.scss";

.phone-select-wrap {
  display: flex;
  column-gap: 0.5rem;
  cursor: pointer;
  max-width: 25rem;

  border-radius: 0.25rem;

  & > button {
    max-width: 25rem;
    min-width: 5rem;
    // height: 3.125rem;
    padding: 0 0.75rem;
    background-color: #fff;
    border-radius: 0.25rem;
    border: 0.0625rem solid $gray-300;
    font-size: 0.875rem;
    // font-weight: bold;
    color: $black-900;
    @include between-center;
    @include transition2;

    &::after {
      @include pseudo("icon-arrow-down.png", 0.75rem, 0.75rem);
      transition: all 0.2s ease-in-out;
    }
    &:disabled {
      // color: $black-900;
      background-color: $gray-200;
    }

    & > span {
      width: auto;
    }

    &.on::after {
      transform: rotate(-180deg);
    }

    @media (max-width: $md) {
      height: 2.5rem;
      // font-size: 0.75rem;
    }
  }
}

.option-wrap {
  display: none;
  // position: absolute;
  // top: 3.375rem;
  // left: 0;
  // right: 0px;
  // z-index: 21;
  // max-width: 25rem;
  // min-width: 12.75rem;
  min-height: 12.5rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  overflow: hidden;

  border: 1px solid red;

  @media (max-width: $md) {
    height: 12.5rem;
  }

  ul {
    min-height: 5rem;
    height: inherit;
    background-color: #fff;

    & > li {
      padding: 0.25rem 0.5rem;
      text-align: left;
      cursor: pointer;
      @include between-center;

      &.selected {
        background-color: $gray-250;
      }
      &:hover {
        background-color: $gray-250;
      }

      @media (max-width: $md) {
        span {
          font-size: 0.75rem;
        }
      }
    }
    span {
      display: inline-block;
      width: 33%;
      padding: 0.25rem 0.25rem;
      font-size: 0.875rem;
    }
    .list-title {
      position: sticky;
      left: 0;
      right: 0;
      top: -0.0625rem;
      z-index: 5;
      height: 2.5rem;
      background-color: inherit;
      border-bottom: 0.0625rem solid $gray-400;

      &:hover {
        background-color: #fff;
      }
    }

    &.on {
      display: block;
      height: 12.5rem;
      overflow: scroll;
      transition: all 0.2s ease-in-out;
      @include noscroll;
    }
  }

  &.on {
    display: block;
    border: 0.0625rem solid rgba($gray-400, 0.6);
    position: absolute;
    top: 3.375rem;
    left: 0;
    right: 0px;
    z-index: 21;
    // max-width: 25rem;
    min-width: 12.75rem;
    box-sizing: border-box;
    overflow: hidden;

    @media (max-width: $md) {
      top: 3.125rem;
    }
  }
}
