@import "style/index.scss";

.check-wrap {
  margin: 1.25rem 0 1.875rem;

  input[type="checkbox"] {
    display: none;
    & + label {
      cursor: pointer;
      display: flex;
      &::before {
        line-height: 25px;
        padding: 0 0 0 35px;
        letter-spacing: -0.04em;
        @include pseudo("icon_checkbox_off.png", 1.5rem, 1.5rem);
      }
    }
    &:checked + label::before {
      @include pseudo("icon_checkbox_on.png", "auto", 1.5rem);
    }
  }
}

.check-btn {
  input[type="checkbox"] {
    & + label {
      padding: max(0.75rem, 0.5rem);
      margin-right: 0.75rem;
      border-radius: 0.25rem;
      border: 0.0625rem solid $gray-300;
      font-size: 0.75rem;
      color: $black-300;
    }

    &:checked + label {
      border-color: $gray-650;
      color: $black-550;
    }
    &:hover + label {
      border-color: $gray-650;
      color: $black-550;
    }

    &:disabled + label {
      background-color: $gray-300;
      border-color: $gray-650;
      cursor: no-drop;
    }
  }
}

.checkbox-wrap {
  font-size: 0.875rem;
  @include start-center;
  & > label {
    margin-left: 0.5rem;
  }

  & > input[type="checkbox"] {
    width: 1.25rem;
    height: 1.25rem;
    flex: none;
    display: block;
  }
}

.check-item {
  font-size: 0.875rem;
  margin: 0.875rem 0;
  @include between-center;

  &:first-child {
    padding-bottom: 0.75rem;
    border-bottom: 0.0625rem solid $gray-750;
  }

  & > a,
  button {
    &::after {
      transform: rotate(180deg);
      @include pseudo("icon_gnb_arrow_black.svg", 1rem, 0.5rem);
      background-position: 2px 0px;
    }

    @media (max-width: $md) {
      font-size: 0.75rem;

      &::after {
        margin-left: 0.25rem;
        @include pseudo("icon_gnb_arrow_black.svg", 0.75rem, 0.5rem);
        background-position: 2px -2px;
      }
    }
  }
}
