$red-300: #e4464e;
$red-350: #e51f22;
$red-400: #f61010;
$red-500: #f22e31;
// $red-500: #f22e31;
// $red-600: #e51f22;
$red-600: #d43434;
$red-650: #f7444e;

$red-750: #fd3930;

$indigo-400: #39405a;
$indigo-500: #2a3044;
$indigo-600: #212636;

$gray-10: #eaeaea;
$gray-50: #f9fafe;
$gray-100: #edeef0;
$gray-150: #d8d8d8;
$gray-200: #eff0f4;
$gray-250: #d6d7dc;
$gray-300: #dfe3e9;
$gray-350: #eef0f5;
$gray-400: #afb2bd;
$gray-450: #ccc;
$gray-500: #afb2bd;
$gray-550: #e6e6e8;
$gray-600: #949596;
$gray-650: #74767f;
$gray-700: #f7f7f7;
$gray-750: #e6e6e6;

$black-200: #6c707c;
$black-300: #3d3d3d;
$black-350: #494949;
$black-400: #333;
$black-450: #3d4666;
$black-500: #313338;
$black-550: #202020;
$black-900: #121212;

$green-350: #00b300;
$green-300: #009900;
$green-450: #00e600;

$blue-500: #0000ff;
$blue-200: #000066;

$orange-400: #e85d04;
