@import "style/index.scss";

.ag-theme-quartz {
  .ag-root-wrapper-body {
    &.ag-layout-normal {
      height: 100%;
      min-height: 12.5rem;
      max-height: 50rem;
      font-size: 1rem;
    }
  }

  .ag-overlay-loading-center {
    display: none;
  }
}

// .ag-theme-quartz,
// .ag-theme-quartz-dark {
//   --ag-header-height: 30px;
//   --ag-header-foreground-color: white;
//   --ag-header-background-color: black;
//   --ag-header-cell-hover-background-color: rgb(80, 40, 140);
//   --ag-header-cell-moving-background-color: rgb(80, 40, 140);
// }
// .ag-theme-quartz .ag-header,
// .ag-theme-quartz-dark .ag-header {
//   // font-family: cursive;
// }
// .ag-theme-quartz .ag-header-group-cell,
// .ag-theme-quartz-dark .ag-header-group-cell {
//   font-weight: normal;
//   font-size: 40px;
// }
// .ag-theme-quartz .ag-header-cell,
// .ag-theme-quartz-dark .ag-header-cell {
//   min-width: 4.375rem;
//   width: 100px;
//   height: 60px;
//   font-size: 18px;
// }

.ag-paging-panel {
  &.ag-unselectable {
    width: 100%;
    justify-content: center;
  }
}

.grid-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*******************************************/

.data-list-wrap {
  position: relative;
  min-width: 50rem;
  min-height: 38.75rem;
  margin: 1.25rem;
  padding: 1.25rem 2.5rem;
  background-color: #fff;
  overflow-x: hidden;

  .data-cont {
    position: relative;
    max-width: 100%;
  }

  .table-title + div {
    width: inherit;
    overflow-x: scroll;
  }
  table {
    width: 100%;
    // min-height: 30.25rem;

    tbody {
      // border-bottom: 1px solid $gray-400;
    }

    tr {
      height: 2.75rem;
      &:hover {
        background-color: rgba($gray-200, 0.4);
      }
      &.drop {
        background-color: $gray-100;
      }
    }

    th,
    td {
      min-width: 6.25rem;
      min-height: 2.75rem;
      height: 2.75rem;
      font-size: 0.875rem;
      border-top: 0.0625rem solid $gray-400;
      border-bottom: 0.0625rem solid $gray-400;
    }
    span {
      display: block;
    }

    .lds-wrap > td {
      width: 100%;
      height: 4rem;
      border: none;
    }

    th {
      background-color: $gray-50;
      border-right: 0.0625rem solid $gray-100;

      &:first-child {
        border-left: 0.0625rem solid $gray-100;
      }
    }

    td {
      height: fit-content;
      text-align: center;
      font-size: 0.875rem;
      cursor: pointer;

      button {
        // text-decoration: underline;

        &:disabled {
          cursor: no-drop;
          // text-decoration: none;
        }
        &.btn-n-use {
          padding: 0.25rem;
        }
        &.n-use {
          width: 100%;
          height: 100%;
          font-weight: bold;
          color: $red-400;
        }

        &.done {
          @extend .n-use;
          color: $black-900;
        }
        &.sll {
          color: green;
          font-weight: bold;
        }
      }

      span {
        &[data-sts] {
          font-weight: bold;
        }
        &[data-sts="C"] {
          color: $green-300;
        }
        &[data-sts="D"] {
          color: $red-400;
        }
        &[data-sts="U"] {
          color: $black-900;
        }
      }
    }
  }

  // .loading {
  //   height: 12.5rem;
  // }

  // .no-data {
  //   height: 12.5rem;
  //   border-bottom: none;
  // }

  @media (max-width: $md) {
    padding: 1.25rem;

    .data-cont {
      max-width: 100%;
    }

    table {
      th,
      td {
        height: 2.75rem;
        font-size: 0.75rem;
      }
    }
  }
}

.table-title {
  // padding: 1.25rem;
  padding: 0 1.25rem 1.25rem;
  @include between-center;

  h3 {
    // font-weight: 500;
    span {
      padding: 0 0.75rem;
      font-size: 1rem;
      font-weight: bold;
    }

    b {
      padding: 0 0.5rem;
      color: $red-600;
    }
  }
}

.joiAprYn {
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  background-color: rgba($green-300, 0.9);
  color: #fff;
  font-weight: bold;
}

.soYn {
  background-color: $gray-400;
  color: #fff;
  cursor: no-drop;
  @extend .joiAprYn;
}
