$pc: 90rem;
$md: 75rem;
$head: 4rem;

.flex {
  display: flex;
}

.justify-center {
  display: flex;
  align-items: center;
}

@mixin direction($direction: column) {
  flex-direction: $direction;
}

@mixin between-center {
  @extend .justify-center;
  justify-content: space-between;
}

@mixin center {
  @extend .justify-center;
  justify-content: center;
}

@mixin start-center {
  @extend .justify-center;
  justify-content: flex-start;
}

@mixin end-center {
  @extend .justify-center;
  justify-content: flex-end;
}

@mixin transition2 {
  transition: all 0.2s ease-in-out;
}

@mixin flex($direction: row, $horizontal: between-center, $vertical: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $horizontal;
  align-items: $vertical;
}

@mixin noscroll {
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin boxShadow($color) {
  box-shadow: 0 0.9375rem 1rem $color;
}

@mixin pseudo($url, $size, $bgsize) {
  display: inline-block;
  content: "";
  width: $size;
  height: $size;
  background: url("/assets/icon/" + $url) no-repeat;
  background-size: $bgsize;
}
