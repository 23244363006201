@import "style/index.scss";

.radio-btn {
  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + label {
    display: inline-block;
    min-width: 2.5rem;
    max-width: 7.5rem;
    width: 100%;
    height: 2.125rem;
    // padding-top: 0.5rem;
    padding: 0.5rem max(0.75rem, 0.5rem);
    margin-right: 0.25rem;
    border-radius: 17px;
    border: 0.0625rem solid $gray-450;
    box-sizing: border-box;
    text-align: center;
    color: $gray-650;
    font-size: 0.75rem;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      border-color: $black-500;
      color: $black-500;
    }

    &.square {
      width: auto;
      height: 2.5rem;
      border-radius: 0.25rem;
      color: $gray-500;
      background-color: #fff;
    }
  }
  input[type="radio"]:checked + label {
    background-color: $gray-500;
    color: #fff;

    &.square {
      background-color: $gray-200;
      border-color: $black-500;
      color: $black-900;
    }
  }

  input[type="radio"]:disabled + label {
    opacity: 0.8;
    cursor: no-drop;
  }

  input[type="radio"]:read-only + label {
    opacity: 0.8;
    cursor: no-drop;
  }
}

.radio-wrap {
  display: flex;

  input[type="radio"] {
    display: none;

    & + label {
      // width: 100%;
      column-gap: 0.75rem;
      margin-right: 0.75rem;
      cursor: pointer;
      @include start-center;

      &::before {
        content: "";
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        border: 0.0625rem solid $gray-450;
      }
      &.square {
        &::before {
          border-radius: 0%;
        }
      }
    }
    &:checked + label {
      &::before {
        content: "";
        border: 0.2813rem solid $black-400;
        @include transition2;
      }
    }

    &:disabled + label {
      cursor: no-drop;
      &::before {
        background-color: $gray-200;
      }
    }
  }
}

.radio-content {
  padding: 0.5rem 0;
  text-align: left;
  flex: 1;

  input[type="radio"] {
    & + label {
      min-width: 6rem;
      width: auto;
      // height: 2.5rem;
      height: 2.125rem;
      font-size: 0.875rem;
      text-align: center;
      background-color: #fff;
      border: 0.0625rem solid $gray-300;
      border-top-right-radius: 0.75rem;
      overflow: hidden;
      transition: all 0.15s linear;

      // clip-path: polygon(0% 0%, 92% 0%, 100% 20%, 100% 100%, 0% 100%);
      &:hover {
        background-color: rgba($gray-300, 0.3);
        transition: all 0.15s linear;
        // &::before {
        //   background-color: rgba(#dfe3e9, 0.3);
        //   transition: all 0.15s linear;
        // }
      }

      &::before {
        display: inline-flex;
        content: attr(data-label);
        // clip-path: polygon(0% 0%, 92% 0%, 100% 20%, 100% 100%, 0% 100%);
        width: 100%;
        height: 100%;
        color: $black-900;
        @include center;
      }

      @media (max-width: $md) {
        width: auto;
        height: 2rem;
        font-size: 0.75rem;
        padding: 0.3125rem 0.5rem;
      }
    }

    &:checked {
      & + label {
        border-bottom: 0;
        font-weight: bold;
        background-color: rgba($gray-300, 0.6);
        border-color: rgba($gray-300, 0.6);
        &::before {
          transition: all 0.1s linear;
        }
      }
    }
    &:disabled {
      & + label {
        cursor: no-drop;
      }
    }
  }

  // textarea {
  //   display: block;
  //   // max-width: 75rem;
  //   width: 100%;
  //   max-height: 37.5rem;
  //   min-height: 2.75rem;
  //   // height: 100%;
  //   padding: 0.5rem 0.875rem;
  //   margin-top: -0.375rem;
  //   font-size: 0.875rem;
  //   line-height: 1rem;
  //   word-wrap: break-word;
  //   word-break: keep-all;
  //   border-radius: 0.25rem;
  //   border: 0.0625rem solid $gray-300;
  //   resize: none;
  //   outline: none;

  //   border: 1px solid red;

  //   @include noscroll;

  //   @media (max-width: $md) {
  //     padding: 0.5rem;
  //     min-width: 10rem;
  //     min-height: 2.5rem;
  //   }
  // }
}
