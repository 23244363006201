@import "style/index.scss";

.entry-wrap {
  max-width: 43.75rem;

  flex-direction: column;
  @include center;
}

.entry-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15rem;
  height: 15rem;
  border-radius: 0.625rem;
  border: 0.1875rem solid $gray-300;
  background-color: #fff;
  overflow: hidden;

  &:hover {
    border: 0.1875rem solid $black-400;
  }

  &::after {
    margin-top: 1.25rem;
    @include pseudo("icon_partner.png", 12.5rem, 12.5rem);
  }

  & > span {
    width: 100%;
    height: 3.9375rem;
    padding: 1.125rem 0;
    background-color: $gray-50;
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
  }
}

.signup-btn {
  min-width: 18.75rem;
  margin-top: 1.875rem;
  padding-top: 5rem;
  border-top: 0.0625rem solid $gray-300;
  @include center;

  button {
    width: 12.5rem;
    // font-size: 0.875rem;
  }
}

.check-item-wrap {
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;

  .check-wrap {
    margin-top: 0;
    margin-bottom: 1.25rem;
    & > label {
      font-weight: 600;
    }
  }
}

.term-cnt {
  max-width: 100%;
  min-width: 10rem;
  height: 100%;
  padding: 1.25rem;
  margin: 0 auto;
  overflow-y: hidden;
  font-size: 0.625rem;
  background-color: #fff;
}

.term-title {
  & > button {
    width: 25%;
    padding: 0.5rem 1.25rem;
    font-size: 0.875rem;
    font-weight: bold;
    border-bottom: 0.0625rem solid $indigo-600;

    &.on {
      border: 0.0625rem solid $indigo-600;
      border-bottom: none;
    }

    // &.on {
    //   background-color: $indigo-600;
    //   color: #fff;
    //   border-top: 0.0625rem solid $indigo-600;
    // }
  }
}

.term-title + textarea {
  display: block;
  width: 100%;
  min-height: calc(100vh - 6.25rem);
  padding: 0.875rem;
  margin-top: 1.25rem;
  font-size: 0.875rem;
  line-height: 1rem;
  background-color: #fff;
  word-wrap: break-word;
  word-break: keep-all;
  border-radius: 0.25rem;
  resize: none;

  @include noscroll;

  @media (max-width: $md) {
    padding: 0.5rem;
    min-width: 10rem;
  }
}

.modal-ctn {
  padding: 1.25rem;
  font-size: 0.875rem;
  line-height: 1.875rem;
}

.modal-wrap {
  .term-cont {
    max-height: calc(95vh - 12.5rem);
    padding-top: 2.5rem;
    padding-right: 1.25rem;
    padding-top: 2.5rem;
    padding-bottom: 1.25rem;
    text-align: left;

    overflow-y: auto;
    @media (max-width: $md) {
      width: auto;
      padding-top: 2.5rem;
      padding-right: 1rem;
      // padding-bottom: 3.75rem;
      margin: 0 -1rem 1.25rem 0;
      overflow-y: auto;
      overflow-x: hidden;
    }

    & > section {
      margin-bottom: 1rem;

      @media (max-width: $md) {
        margin-bottom: 0;
      }

      h3 {
        margin: 1rem 0;
        @media (max-width: $md) {
          margin: 0.25rem 0;
        }
      }
      .inner {
        padding-left: 1rem;

        @media (max-width: $md) {
          padding-left: 0.25rem;
        }
        table {
          margin: 1rem 0;
          border-top: 0.1rem solid #ddd;
          border-left: 0.1rem solid #ddd;
          @media (max-width: $md) {
            width: webkit-fill-available;
            margin: 0.5rem 0;
          }

          th {
            min-width: 2.5rem;
            padding: 0.5rem;
            font-weight: normal;
            color: #000;
            background: #e8e8e8;
            border-right: 0.1rem solid #ddd;
            border-bottom: 0.1rem solid #ddd;
          }

          td {
            max-width: calc(100% / 5);
            min-width: 2.75rem;
            padding: 0.5rem;
            border-right: 0.1rem solid #ddd;
            border-bottom: 0.1rem solid #ddd;

            @media (max-width: $md) {
              min-width: 2rem;
            }
          }
        }
        ul {
          padding-left: 1.875rem;
          li {
            list-style: disc;
          }
        }
      }
    }

    strong {
      display: block;
    }

    .font-red {
      color: $red-300;
    }

    @media (max-width: $md) {
      h3,
      span,
      p,
      th,
      td,
      strong {
        font-size: 0.875rem;
      }
    }
  }
}
