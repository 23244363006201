@import "style/index.scss";

.pagenation-wrap {
  // max-width: 30rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.25rem 0;
  // margin: 0 auto;
  font-size: 0.75rem;
  // border-top: 1px solid $gray-400;

  ul {
    column-gap: 0.5rem;
    @include center;
  }

  li {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 0.5rem;
    border: 0.0625rem solid $gray-400;
    cursor: pointer;
    @include center;

    &:hover {
      font-weight: bold;
      border-color: $black-500;
    }
  }

  .curr {
    border: 0.0625rem solid transparent;
    background-color: $gray-400;
    color: #fff;
    font-weight: 700;
  }

  .pre-btn,
  .nxt-btn {
    &::after {
      content: "";
      width: 1.875rem;
      height: 1.875rem;
      background: url("/assets/icon/icon_gnb_arrow_black.svg") no-repeat;
      background-size: 0.625rem;
      background-position: 8px 6px;
    }
  }

  .nxt-btn {
    &::after {
      background-position: 7px 7px;
      transform: rotate(-180deg);
    }
  }
}
