@import "style/index.scss";

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  height: $head;
  padding: 0 1.25rem;
  background-color: #fff;
  border-bottom: 0.0625rem solid $gray-750;
  box-shadow: 0.25rem 0.25rem 0.5rem $gray-150;
}

.header-wrap {
  // max-width: 90rem;
  max-width: 100%;
  margin: 0.6875rem auto;
  @include between-center;

  div {
    font-size: 0.875rem;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
}

.left-menu {
  position: relative;
  // padding: 0 0.75rem;
  column-gap: 1.25rem;
  font-weight: bold;
}

.btn-lng {
  display: flex;
  padding: 0.25rem 0.5rem;
  border: 1px solid $gray-400;
  border-radius: 0.5rem;

  &[data-icon="KOR"]::before {
    margin-right: 0.5rem;
    @include pseudo("icon_lang_kor.svg", 1.5rem, 1.5rem);
  }
  &[data-icon="ENG"]::before {
    margin-right: 0.5rem;
    @include pseudo("icon_lang_eng.svg", 1.5rem, 1.5rem);
  }
}

.languages {
  position: absolute;
  top: 3.125rem;
  right: 0;
  width: 5rem;
  min-height: 4rem;
  // padding: 0.5rem;
  text-align: center;
  border-radius: 0.75rem;
  border: 1px solid $gray-400;
  background-color: #fff;
  overflow: hidden;

  & > li {
    padding: 0.25rem 0.5rem;
    display: flex;
    // cursor: pointer;
    cursor: no-drop;

    &:hover {
      background-color: $gray-200;
    }
  }

  button {
    width: auto;
    height: auto;
    line-height: 1.625rem;
    border-radius: 0.875rem;
    color: $black-900;
    @include center;
  }
  .kor::before {
    margin-right: 0.5rem;
    @include pseudo("icon_lang_kor.svg", 1.5rem, 1.5rem);
  }
  .eng {
    &::before {
      margin-right: 0.5rem;
      @include pseudo("icon_lang_eng.svg", 1.5rem, 1.5rem);
    }
  }
}

// .languages {
//   display: flex;
//   flex-direction: column;
//   position: absolute;
//   top: 1rem;
//   right: 0;
//   width: 4.5rem;
//   height: 3.75rem;
//   padding: 0 0.375rem;
//   // font-family: $font-R;
//   font-size: 0.75rem;
//   border-radius: 0.875rem;
//   border: 0.0625rem solid rgba($black-900, 0.2);
//   background-color: #fff;
//   overflow: hidden;

//   button {
//     width: auto;
//     height: auto;
//     line-height: 1.625rem;
//     border-radius: 0.875rem;
//     color: $black-900;
//     @include center;

//     &:hover {
//       color: $black-900;
//     }
//   }
//   .KOR::before {
//     margin-right: 0.25rem;
//     @include pseudo("icon_lang_kor.svg", 1.5rem, 1.5rem);
//   }
//   .ENG {
//     &::before {
//       margin-right: 0.25rem;
//       @include pseudo("icon_lang_eng.svg", 1.25rem, 1.25rem);
//     }
//   }

//   &:hover {
//     height: 3.75rem;
//     border-color: $black-900;
//     .ENG {
//       display: flex;
//       @include center;
//     }
//   }
// }
