@import "style/index.scss";

.home-wrap {
  min-height: calc(100vh - 4rem);
  max-height: 100%;
  padding: 1.25rem 2.5rem;
  background-color: #fff;
  font-size: 1rem;

  & > h3 {
    padding: 1.25rem 0;
  }

  .home {
    h4 {
      font-size: 1rem;
    }

    &.card {
      width: 100%;
      max-width: 100%;
      min-height: 12.5rem;
      padding: 0.875rem;
      margin-top: 2.5rem;
      box-shadow: none;
      border: 1px solid $gray-400;
      @extend .card-form;
    }
    &.card-title {
      width: inherit;
      height: 2.8125rem;
      padding-bottom: 0.875rem;
      margin-bottom: 0.875rem;
      border-bottom: 0.0625rem solid $black-900;
      @include between-center;
    }

    &.title {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      margin-bottom: 0;
      border-bottom: none;
      @extend .card-title;

      & > button {
        &:disabled {
          & > i {
            color: $black-450;
          }
        }
        &:active {
          transform: scale(1.2);
        }
      }
    }
    &.title + div {
      position: relative;
      min-height: 10rem;
      padding: 0;
      // border-bottom: 1px solid;
    }

    // .fa-plus-square-o,
    // .fa-refresh,
    // .fa-plus-circle {
    //   font-size: 1.25rem;
    //   color: $black-900;
    //   cursor: pointer;
    //   &:hover {
    //     color: $black-450;
    //     cursor: pointer;
    //   }
    // }

    & > ul {
      padding: 0.75rem;
      font-size: 0.875rem;

      & > li {
        line-height: 1.5rem;
        list-style: disc;
      }
    }

    table {
      width: 100%;
      font-size: 0.875rem;
      border-collapse: collapse;
      border: 1px solid $gray-400;

      th {
        background-color: $gray-200;
      }
      tr {
        height: 2.75rem;
        border-bottom: 0.0625rem solid $black-900;
        &:hover {
          background-color: rgba($gray-200, 0.4);
        }
      }
      td {
        flex: 1;
        height: inherit;
        text-align: center;
        border-bottom: 1px solid $gray-400;
      }
    }

    &.day-list {
      & > h3 {
        padding: 1.25rem 0;
        border-bottom: 1px solid $black-900;
      }
    }
  }

  .btn-more {
    width: 7.5rem;
    height: 2.125rem;
    padding: 0 1.25rem;
    margin-top: 2.5rem;
    border-radius: 0.5rem;
    border: 0.0625rem solid $gray-500;
    font-size: 0.875rem;
    font-weight: bold;
    @include center;

    // &::before {
    //   margin-right: 0.75rem;
    //   @include pseudo("icon_plus_sm.png", 1.5rem, 1.5rem);
    // }

    &:hover {
      border-color: $black-900;
    }
  }
}

.icon-rotate {
  animation: reload 2s linear infinite; //delay iteration-count direction fill-mode;
}

@keyframes reload {
  form {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pay-inf {
  text-align: left;
  & > div {
    display: flex;
    span {
      &:first-child {
        width: 12.5rem;
      }
    }
  }
}
